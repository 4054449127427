import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

export default function Footer() {
  return (
    <Box
      sx={{
        p: 4,
        position:'sticky'
      }}
      component="footer"
    >
      <Container maxWidth="sm">
        <center>
        <Typography variant="body" color="#5900BD" align="center">
          {"Copyright © "}
          <Link color="#5900BD" href="https://bennatech.com" target="_blank">
            bennatech
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
        </center>
      </Container>
    </Box>
  );
}