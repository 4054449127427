import React, { useState, useEffect, useRef } from "react";
import { TextField, Grid, Stack, Box, Button, CircularProgress, Modal, IconButton, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Visibility as VisibilityIcon, Clear as ClearIcon } from "@mui/icons-material";
import axios from "axios";
import { Link } from "react-router-dom";
import { formatDate } from "../../components/common/FormatDate";
import { formatPrice } from "../../components/common/FormatPrice";
import { formatPhoneNumber } from "../../components/common/FormatNumber";
import CustomButton from "../../components/common/CustomButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "7px",
  boxShadow: 24,
  p: 1,
};

const ArchivedServices = (props) => {
  const openModal = (row) => {
    setSelectedRow(row);
    setModalOpen(true);
  };
  const closeModal = () => {
    setSelectedRow(null);
    setModalOpen(false);
  };
  const componentRef = useRef();

  const [archivedServices, setArchivedServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const fetchArchivedServices = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/service/name`, { name: localStorage.getItem('name') });

      const filteredServices = response.data.filter(service => service.isArchived);

      const sortedServices = filteredServices.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));

      setArchivedServices(sortedServices.reverse());
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchArchivedServices();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredServices = archivedServices
    .filter((service) => {
      const serviceStartDate = new Date(service.createdAt);
      const serviceEndDate = new Date(service.updatedAt);

      const startCondition = startDate ? serviceStartDate >= new Date(startDate) : true;
      const adjustedEndDate = new Date(endDate);
      adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);

      const endCondition = endDate ? serviceEndDate < adjustedEndDate : true;

      return startCondition && endCondition;
    })
    .filter((service) =>
      (service.serviceName?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
      (service.serviceGsmno?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
      (service.serviceAddress?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
      (service.serviceDesc?.toLowerCase() || "").includes(searchTerm.toLowerCase())
    );
  const columns = [
    {
      flex: 0.1,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <Button
            style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', color: '#008000' }}
            component={Link}
            onClick={() => openModal(params.row)}
          >
            <VisibilityIcon />
          </Button>
        </Stack>
      ),
    },
    { field: "serviceName", headerName: "Ad Soyad", flex: 1 },
    {
      field: "dateRange",
      headerName: "Başlangıç/Bitiş Tarihi",
      flex: 1,
      renderCell: (params) => (
        <>
          {formatDate(params.row.createdAt)}
          <br />
          {formatDate(params.row.updatedAt)}
        </>
      ),
    },
    { field: "serviceGsmno", headerName: "Telefon Numarası", flex: 1 },
    { field: "serviceAddress", headerName: "Adres", flex: 1 },
    { field: "serviceDesc", headerName: "Açıklama", flex: 1 },
    {
      field: "serviceBrandAndModel",
      headerName: "Marka/Model",
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.serviceBrand}
          <hr />
          {params.row.serviceModel}
        </>
      ),
    },
    { field: "serviceType", headerName: "Atölye/Servis", flex: 1 },
    {
      field: "servicePrice",
      headerName: "Ücret",
      flex: 1,
      valueFormatter: (params) => formatPrice(params.value),
    },
    {
      field: "actions",
      headerName: "İşlemler",
      flex: 0.8,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <CustomButton backgroundColor="#d1a507" component={Link} to={`/update/${params.row.id}`}>
            Düzenle
          </CustomButton>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <center>
        <Box spacing={2} sx={{ width: '97.5%', marginTop: {xs: '65px' , sm: '75px'}}}>
          <Grid
            container
            marginTop="5.5rem"
            padding="1.2rem"
            marginBottom="1rem"
            sx={{ width: '95%', bgcolor:'#fefefe', borderRadius:'16px' }}
            justifyContent="center"
            spacing={2}>
            <Grid item
              xs={12}>
              <TextField
                size="small"
                fullWidth
                label="Ad Soyad / Telefon Ara / Adres / Açıklama"
                value={searchTerm}
                onChange={handleSearch}
              />
            </Grid>
            <Grid
              item
              alignItems="center"
              md={4}
              xs={6}>
              <TextField
                size="small"
                fullWidth
                focused
                label="Başlangıç Tarihi"
                type="date"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </Grid>
            <Grid item
              alignItems="center"
              md={4}
              xs={6}>
              <TextField
                size="small"
                fullWidth
                focused
                label="Bitiş Tarihi"
                type="date"
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
              />
            </Grid>
          </Grid>
        </Box>

        <div style={{ height: "80vh", width: "95%", overflow: "auto", borderRadius: '25px' }}>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '750px' }}>
              <CircularProgress />
            </div>
          ) : (
            <DataGrid
              sx={{ bgcolor: 'white', minWidth: '1280px' }}
              rows={filteredServices}
              columns={columns}
              disableSelectionOnClick
              getRowId={(row) => row._id}
            />
          )}
        </div>
        <br />

      </center>
      <Modal open={modalOpen} onClose={closeModal} sx={{ borderRadius: '20px' }}>
        <Box sx={style}>
          <IconButton
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
            onClick={closeModal}
          >
            <ClearIcon />
          </IconButton>
          <table className="receipt-table" ref={componentRef}>
            <center>
              <Typography sx={{ padding: '1.2rem', fontSize: '14px', fontWeight: 'bold' }}>
                <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold', letterSpacing: '2px', lineHeight: '10px' }}>{localStorage.getItem('name')}</Typography>
                BEYAZ EŞYA TEKNİK SERVİS
                <br />
                <br />
                {selectedRow && (
                  <>
                    Fiş Tarihi: {formatDate(selectedRow.createdAt)}
                    <br />
                    <br />
                    Ad Soyad: {selectedRow.serviceName}
                    <br />
                    <br />
                    Telefon No: {selectedRow ? formatPhoneNumber(selectedRow.serviceGsmno) : null}
                    <br />
                    <br />
                    Adres: {selectedRow.serviceAddress}
                    <br />
                    <br />
                    Açıklama: {selectedRow.serviceDesc}
                    <br />
                    <br />
                    Ürün Marka: {selectedRow.serviceBrand}
                    <br />
                    <br />
                    Ürün Model: {selectedRow.serviceModel}
                    <br />
                    <br />
                    Tutar: {selectedRow ? formatPrice(selectedRow.servicePrice) : null}
                  </>
                )}
              </Typography>
            </center>
          </table>
        </Box>
      </Modal>
    </>
  );
};

export default ArchivedServices;

